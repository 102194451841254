#what-we-do {
  display: flex;
  flex-direction: column;
  color: #fff;
  margin: 0% 4%;
  padding: 50px 20px;
  text-align: center;
  }

  .text-container{
    display: flex;
    justify-content: center;
  }

  #what-we-do h1{
    text-align: center;
    width: auto;
    font-size: clamp(2rem, 5vw + 1rem, 3.5rem);
    position: relative;
    margin-bottom: 20px;
  }

  #what-we-do p {
    font-size: clamp(1rem, 1vw, 1.2rem);
    line-height: 1.8;
    text-align: left;
  }
  
  #what-we-do p strong {
    color: var(--primary-color);
  }
  