.portfolio{
  margin: 2rem 3rem;
}

.portfolio .portfolio-intro-container{
  margin-bottom: 3rem;
}

.portfolio-text-wrapper{
  display: flex;
}

.portfolio .portfolio-heading{
  font-size: clamp(3rem, 4vw, 3rem);
  margin: 3rem 0rem;
}

.portfolio-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.advantage-list{
  display: flex;
  gap: 1rem;
  align-items: center;
}

.advantage-list ul {
  list-style: none;
  padding: 0rem 2rem;
  height: 100%;
  flex: 2;
}



.advantage-list .image-container{
  width: 100%;
  height: 100%;
  flex: 1;
}
.advantage-list .image-container img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: contain;
}


.advantage-list ul li {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  display: flex;
  align-items: baseline;
}

.advantage-list ul li i {
  margin-right: 10px;
  color: white;
}


/* Process Tree CSS */

.process-tree-container{
  margin: 3rem 1rem;
}


.process-tree {
  margin-top: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 20px;
  margin: 1rem 4rem;
}

.process-tree-container h2{
  text-align: center;
  font-size: 2.5rem;
  margin-top: 4rem;
}


.process-step {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  padding: 25px 15px;
  border-radius: 5px;
}

.process-tree .left {
  transform: translateX(-60%);
  }
  
   .process-tree .right{
    transform: translateX(60%);
  } 

  .process-tree .left .step-content::after{
    position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background-color: white;
  top: 45%;
  right: 5px;
  z-index: -1;
  transform: rotate(45deg);
}
.process-tree .right .step-content::before{
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background-color: white;
  top: 45%;
  left : 5px;
  z-index: -1;
  transform: rotate(45deg);
}



.process-tree .line{
  position: absolute;
  height: 80%;
  width: 2px;
  top: 10%;
  background: linear-gradient(to bottom, yellow, green);
  transform-origin: bottom;

}

.process-tree .line::before{
  position: absolute;
  content: "";
  background-color: yellow;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  top: 0;
  transform: translateX(-50%);
}
.process-tree .line::after{
  position: absolute;
  content: "";
  background-color: rgb(0, 202, 0);
  border-radius: 50%;
  height: 10px;
  width: 10px;
  bottom: 0;
  transform: translateX(-50%);
}

.process-step .step-content{
  display: flex;
  padding: 30px 20px;
  border-radius: 10px;
  flex-direction: column;
  background-color: white;
  color: black;
}

.step-title {
  font-weight: bold;
  margin-bottom: 10px;
  color: rgb(29, 29, 29);
  font-size: 1.2em;
}


.select-a-portfolio-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.select-a-portfolio-container .select-portfolio-header{
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1.5rem;
}
.select-a-portfolio-container span{
  width: 70%;
  font-size: 1rem;
  color: rgb(151, 151, 151);
  text-align: center;
}

.select-a-portfolio-container .select-portfolio-header h1{
  border-bottom: 1px solid yellow;
  padding-bottom: 10px;
  margin-bottom: 2rem;
  margin-top: 2rem;
  font-size: 2.5rem;
}


.service-details-icons-container{
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  row-gap: 100px;
  column-gap: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 30px;
  margin-bottom: 3rem;
}

.service-details-icons-container .item{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.service-details-icons-container .item svg {
  width: 60px;
  height: 60px;
  background-color: rgb(255, 255, 255); 
  color: rgb(31, 31, 31);
  padding: 10px; 
  border-radius: 50%; 
  clip-path: circle(50% at 50% 50%); 
}


.portfolio-templates-container{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 10px;
  margin-bottom: 5rem;
}

.portfolio-templates-container .portfolio-template-card{
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}


.portfolio-templates-container .portfolio-template-card .card-actions{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.template-preview-image{
  position: relative;
  width: 400px;
  height: 200px;
  overflow: hidden;
}

.template-preview-image:hover::before{
  width: 100%;
  opacity: 70%;
  background-color: black;
  transition: width 0.1s ease-in-out;
  z-index: 1;
  border: 2px rgba(255, 255, 255, 0.697) solid;
  box-sizing: border-box;
}

.template-preview-image:hover img{
  filter: blur(5px);
}

.template-preview-image::before{
  position: absolute;
  content: "";
  width: 5px;
  height: 100%;
  background-color:yellow;
}

.portfolio-templates-container .portfolio-template-card img{
  width: 400px;
  height: 200px;
  z-index: -1;
}

.template-preview-image .view-text{
  position: absolute;
  display: flex;
  color: white;
  text-align: center;
  width: 100%;
  height: 100%;
  align-items: center;
  font-style: italic;
  opacity: 0;
  z-index: 1;
}

.template-preview-image:hover .view-text{
  opacity: 100%;
  transition: opacity 0.2s ease-in-out;
}


.portfolio-templates-container .portfolio-template-card .template-title{
  width: 100%;
  text-align: center;
  margin: 0.5rem 0rem;
  padding: 5px;
}

.quote-btn, .live-preview-btn {
  padding: 10px 20px;
  width: 70%;
  background-color: rgba(0, 209, 178, 0.8); 
  color: #fff; 
  border-radius: 50px; 
  font-size: 1rem;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  z-index: 10; 
}

.get-quote{
  background-color: transparent;
  color: white;
  padding: 0.9rem 2.4rem;
}

.quote-btn:hover, .live-preview-btn:hover {
  background-color: #00d1b2; 
  color: #fff; 
}

@media(max-width:1080px){
  .advantage-list{
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
  }

  /* handle the tree for mobile here */
  .process-tree .left {
    transform: translateX(1%);
  }
  .process-tree .right {
    transform: translateX(1%);
  }

  .process-tree .left .step-content::after{
    left: 5px;
    right: unset;
  }

  .process-tree{
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    align-items: unset;
    margin: 1rem 4rem;
  }

  .process-step{
    width: 250px;
    font-size: 0.75rem;
  }

  .line-container{
    display: flex;
    align-items: center;
  }
  
  .process-tree .line{
    position: relative;
    height: 95%;
    top: unset;
    background-color: white;
  }
  
  .service-details-icons-container{
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

}

@media(max-width:920px){
  
  .portfolio-templates-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }  

  .select-a-portfolio-container span{
    width: 100%;
    padding: 10px;
  }

  .template-preview-image{
    width: 100%;
    height: 150px;
  }

  .portfolio-templates-container .portfolio-template-card img{
    width: 300px;
    height: 150px;
    z-index: -1;
  }

  .select-a-portfolio-container .select-portfolio-header h1{
    font-size: 2rem;
    margin-bottom: 0rem;
  }

  .select-a-portfolio-container span{
    width: 90%;
    font-size: 0.8rem;
    color: rgb(151, 151, 151);
    text-align: center;
  }

}