.team-section {
    padding: 4rem 2rem;
    text-align: center;
    margin: 10px 5%;
  }
  
  .team-section .section-header-meet{
    font-size: 3rem;
  }
  
    .section-header-meet .highlight {
      color: var(--primary-color);
    }
  
  .team-members {
    margin-top: 5%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 25px;
  }
  
  .member {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .badge {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #ffffff; /* White background */
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow */
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
  }

  .badge:hover{
    transform: translateY(-5px);
    transition: all 0.2s ease-in-out;
  }
  
  .member-image {
    width: 90%;
    height: auto;
  }
  
  .member-name {
    margin-top: 20px;
    font-size: 1.2rem;
    color: var(--text-color);
    text-wrap: nowrap;
  }
  .member-designation {
    font-size: 1rem;
    color: rgb(177, 177, 177);
    text-wrap: nowrap;
  }