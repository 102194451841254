#service-section {
    color: #fff;
    margin: 1rem 8rem;
    text-align: center;
  }
  
  .dots{
    display: flex;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
    gap: 30px;

  }

  .dots .dot{
    width: 10px;
    height: 10px;
    background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%);
    border-radius: 50%;
  }
  .text-wrapper{
    display: flex;
    justify-content: center;
  }
  
  .para-primary{
    margin: 1rem 1rem;
  }
  /* Service List Styles */
  .service-list {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  gap: 10px;
  }
  
  .service-list .service{
  display: flex;
  padding: 20px;
  margin-bottom: 20px;
  gap: 20px;
  border-bottom: 1px rgba(255, 255, 255, 0.127) solid;
  }
  
  
  .service-list .service .right{
  flex: 4;
  
  }
  .service-list .service .left{
  text-align: left;
  flex: 6;
  }
  
  .service-list .service .right img{
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  border-radius: 10px;
  }
  
  @media(max-width : 1100px){
  .service-list .service{
    flex-direction: column;
  }

  #service-section{
    margin: 1rem 2rem;
  }

  #service-section .para-primary{
    margin: 1rem 1rem;
    font-size: 1rem;
    text-align: justify;
  }
  }