.career-intro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 2rem;
  gap: 2rem;
}

.career-intro-left {
  flex: 1;
  text-align: left;
}

.career-intro-left h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.career-intro-left p {
  font-size: 1rem;
  color: var(--text-primary);
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.career-intro-left .cta-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #ffffff;
  background-color: var(--button-bg);
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.career-intro-left .cta-button:hover {
  background-color: var(--button-hover-bg);
}

.career-intro-right {
  flex: 1;
  object-fit: cover;
}

.career-intro-right img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  object-fit: cover;
}


.careers-page {
    padding: 2rem 5rem;
    text-align: center;
  }
  
  
  .careers-page h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    text-wrap: nowrap;
  }
  
  .careers-page p {
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }
  
  .job-openings {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .job-opening {
    border-radius: 8px;
    padding: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .job-opening h2 {
    font-size: 1.5rem;
    color: var(--primary-color);
    margin-bottom: 0.5rem;
  }
  
  .job-opening p {
    margin: 0.5rem 0;
  }
  
  .job-opening button {
    margin-top: 1rem;
    padding: 0.75rem 1.5rem;
    background-color: var(--button-bg);
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .job-opening button:hover {
    background-color: var(--button-hover-bg);
  }
  
  @media (max-width: 768px) {
    .career-intro {
      flex-direction: column;
      text-align: center;
    }
  
    .career-intro-left, .career-intro-right {
      width: 100%;
    }
  
    .career-intro-left {
      margin-bottom: 2rem;
    }

    .careers-page h1 {
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }

    .careers-page{
      padding: 1rem;
    }

    .job-openings{
      margin: 0;
    }

  }