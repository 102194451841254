#services {
    color: var(--text-primary); /* White text */
    padding: 50px 5rem;
    text-align: center;
  }
  .services-heading-container{
    display: flex;
    justify-content: center;
  }
  #services h1 {
    font-size: clamp(2rem, 5vw + 1rem, 3.5rem);
    margin-bottom: 40px;
    }
  
  .services-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;    
    gap: 20px;
  }
  
  .service-card {
    display: inline-flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1c1c1e; 
    border-radius: 10px;
    padding: 20px;
    width: 250px;
    height: 300px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .service-card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.7);
  }
  
  .service-card h3 {
    font-size: 1.5rem;
    flex: 1;
    margin: 15px 0;
  }
  
  .service-card p {
    font-size: 1rem;
    flex: 2;
    line-height: 1.6;
    color: var(--text-primary); /* Slightly lighter text for descriptions */
  }
  
  .service-card .learn-more {
    display: inline-block;
    margin-top: 15px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  /* Icon styles */
  .service-card svg {
    flex: 1;
    font-size: 2.5rem;
    color: rgba(255, 255, 255, 0.732); /* Accent color for the icons */
    filter: drop-shadow(10px 5px 2px rgb(0, 0, 0));
    transition: all 0.2s ease-in-out;
  }
  