.quote-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
  }
  
  .quote-popup-content {
    background: linear-gradient(135deg, rgba(255, 234, 0, 0.912), rgba(134, 50, 252, 0.94)), rgba(0, 0, 0, 0.808);
    border-radius: 10px;
    padding: 5rem 2rem;
    width: 40%;
    position: relative;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    text-align: center;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .close-btn:hover {
    color: rgb(150, 0, 0); /* Red color on hover */
  }
  
  h1 {
    color: #fff;
    text-align: left;
    font-size: 2rem;
    margin-bottom: 2.5rem;
  }
  
  .form-label {
    color: #fff; /* Label color */
  }
  
  .form-control {
    width: 80%;
    padding: 0.75rem;
    border: rgba(255, 255, 255, 0.541) solid 1px;
    border-radius: 5px;
    background-color: #33333388; /* Dark background for input fields */
    color: #fff; /* Text color */
    margin-bottom: 2rem;
  }
  
  .form-control::placeholder {
    color: #a7a7a7; /* Placeholder text color */
  }
  
  .quote-textarea {
    border: rgba(255, 255, 255, 0.541) solid 1px;
    background-color: #33333388; /* Dark background for input fields */
    color: #fff; /* Text color */
    border-radius: 5px;
  }
  
.popup-btn{
  background-color: transparent;
  color: white;
  padding: 10px 20px;
  border: 1px white solid;
  border-radius: 5px;
}

.popup-btn:hover{
  background-color: rgba(255, 255, 255, 0.342);
  color: rgb(255, 255, 255);
  border-radius: 5px;
}

.additional-details{
  width: 80%;
    padding: 0.75rem;
    border: none;
    border-radius: 5px;
    border: rgba(255, 255, 255, 0.541) solid 1px;
    background-color: #33333388; /* Dark background for input fields */
    color: #fff; /* Text color */
    margin-bottom: 1.5rem;
  resize: none;
}
.additional-details::placeholder{
  color: #aaa;
}

@media(max-width:850px){
  .quote-popup-content {
    padding: 5rem 2rem;
    width: 70%;
    position: relative;
  }
  
}