
  
  h1, h2, h3, p, label {
    margin: 0;
    padding: 0;
  }
  
  /* Contact Section */
  .contact {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px 20px;
  }
  
  .contact .item {
    flex: 1 1 500px;
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
  }
  
  .contact .left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact .contact-intro {
    text-align: center;
  }
  
  .contact .contact-intro h1 {
    font-size: 2.5rem;
    color: var(--text-primary);
    margin-bottom: 20px;
  }
  
  .contact .contact-intro p {
    font-size: 1.2rem;
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .contact .mail-highlight {
    color: var(--primary-color);
    font-weight: bold;
  }
  
  .contact .contact-image {
    max-width: 100%;
    border-radius: 10px;
  }
  
  .contact .right h1 {
    font-size: 2rem;
    color: var(--text-primary);
    text-align: center;
    margin-bottom: 30px;
  }

  .row-1{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }  
  
  
  .custom-input::placeholder {
    color: rgba(255, 255, 255, 0.403);
  }
  .custom-input {
    background-color: transparent;
    border: rgba(255, 255, 255, 0.371) 1.5px solid;
    width: 100%;
    padding: 15px;
    color: white;
    border-radius: 5px;
  }
  
  .custom-input.textarea{
    resize: none;
  }
  .contact-btn {
    background-color: transparent;
    color: white;
    padding: 1rem 3rem;
    width: 250px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .contact {
      flex-direction: column;
      padding: 30px 15px;
    }
  
    .contact .item {
      flex: 1 1 100%;
    }
  
    .contact .contact-intro h1,
    .contact .right h1 {
      font-size: 2rem;
    }
  
    .contact .contact-intro p {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .contact {
      padding: 20px 10px;
    }
  
    .contact .contact-intro h1,
    .contact .right h1 {
      font-size: 1.5rem;
    }
  
    .contact .contact-intro p {
      font-size: 0.9rem;
    }
  }
  